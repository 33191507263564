import { mapGetters } from 'vuex'

// eslint-disable-next-line no-unused-vars
function handleAbTestRequests ($cookies, redirect, app, abTestId, abTestVariants) {
  if (abTestId && !app.context.route.query?.abtestid) {
    let sessionCookie = $cookies.get('_hod_s')
    const cookieTestMap = new Map(sessionCookie?.abt)
    const testVersion = cookieTestMap.get(abTestId) || 1 + (Math.floor(Math.random() * abTestVariants) % abTestVariants)
    cookieTestMap.set(abTestId, testVersion)
    sessionCookie = {
      ...sessionCookie,
      abt: Array.from(cookieTestMap, ([name, value]) => ([name, value]))
    }
    $cookies.set('_hod_s', sessionCookie, {
      maxAge: 60 * 60 * 24 * 365,
      path: '/',
      sameSite: 'lax'
    })
    redirect(app.context.route.path, {
      ...app.context.route.query,
      abtestid: abTestId,
      abtestvariant: testVersion
    })
  }
}

function removeAbTestQueryParams ($router) {
  const queryParams = new URLSearchParams(window.location.search)
  queryParams.delete('abtestid')
  queryParams.delete('abtestvariant')
  if (queryParams.size !== new URLSearchParams(window.location.search).size) {
    $router.replace(`${window.location.pathname}${queryParams.size > 0 ? '?' + queryParams : ''}`)
  }
}

export default {
  name: 'WildcardPage',
  asyncData ({ $cookies, redirect, app, store, error }) {
    // handleAbTestRequests($cookies, redirect, app, 27, 2)

    // No match means 404 (the route does not exist anywhere and is caught here)
    const route = app.context.route.path
    return store.dispatch('routes/lookupRoute', route).then((result) => {
      if (result === undefined) {
        error({ statusCode: 404, message: 'Page Not Found' })
        // Optional to turn on/off 404 errors to sentry
        // app.$sentryMiddleware('404 - Page Not Found', `Page Not Found: ${route}`)
      } else {
        return result
      }
    })
  },
  data () {
    return {
      transparentNavbar: false
    }
  },
  computed: {
    ...mapGetters({
      headerHeight: 'header/headerHeight'
    }),
    classes () {
      if (this.component === 'storyblok') {
        const page = this.$store.getters['storyblok/getStoryById'](this.id)
        const classes = [
          `block-spacing mbs--${page?.content?.blockSpacingMobile}`,
          `block-spacing dbs--${page?.content?.blockSpacingDesktop}`,
          `bg-color--${page?.content?.backgroundColor}`,
          `header-bgcolor--${page?.content?.headerBackgroundColor}`,
          `header-color--${page?.content?.headerColor}`
        ]
        if (page?.content?.hideFooter === '1') {
          classes.push('hide-footer')
        }
        if (page?.content?.hideStory === '1') {
          classes.push('hide-story')
        }
        if (page?.content?.hideUsp === '1') {
          classes.push('hide-usp')
        }
        if (page?.content?.hideFooterMenu === '1') {
          classes.push('hide-footer-menu')
        }
        return classes
      }
      return []
    }
  },
  async mounted () {
    if (this.data?.content?.transparent_navbar) {
      this.$nextTick(() => {
        this.$store.dispatch('header/setHeaderHeight')
        this.transparentNavbar = true
        this.$store.dispatch('header/setTransparentNavbar', true)
      })
    } else {
      this.transparentNavbar = false
      this.$store.dispatch('header/setTransparentNavbar', false)
    }
    if (this.data?.content?.white_navbar_text) {
      this.$store.dispatch('header/setWhiteNavbarText', true)
    } else {
      this.$store.dispatch('header/setWhiteNavbarText', false)
    }
    if (this.$route.query.cjevent) {
      await this.$backendApi.get(`/cj/cookie/${this.$route.query.cjevent}`)
    }
    removeAbTestQueryParams(this.$router)
  },
  serverPrefetch () {
    // This is allowed if we're behind fastly
    const cachedCountries = this.$store.getters['storyblok/settings']?.cachedCountries?.map(c => c.toLowerCase())
    const slug = this.$route.path.split('/')[1]
    if (this.$config.cachedCountriesPageResponseCacheTtl && cachedCountries.includes(slug)) {
      this.$ssrContext.res.setHeader(
        'cache-control',
        `public, max-age=${this.$config.cachedCountriesPageResponseCacheTtl}, stale-while-revalidate=${this.$config.cachedCountriesPageResponseCacheTtl}`
      )
    } else if (this.$config.pageResponseCacheTtl) {
      this.$ssrContext.res.setHeader(
        'cache-control',
        `public, max-age=${this.$config.pageResponseCacheTtl}, stale-while-revalidate=600`
      )
    }
  }
}
